import actions from './actions';

const initialState = {
  loading: false,
  query: { },
  queryDev: { },
  error: undefined,
  isFailed: false,
  isSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case actions.SEARCH_QUERY_REQUEST:
      return {
        ...state,
        query: payload
      };

    default:
      return state;
  }
};
