import { all, takeEvery, put, call, fork, select } from 'redux-saga/effects';
// import { notification } from 'antd';
import actions from './actions';
import { getClientes, getPeriodos, getPredios, getVendedores } from '../../services/ventas';
import { getToken } from '../selectors';

export function* listRequest() {
  yield takeEvery('LIST_FETCH_REQUEST', function*({ payload }) {
    const token = yield select(getToken);
    const options = { token };
    const resCliente = yield call(getClientes, options);
    const resPeriodos = yield call(getPeriodos, options);
    const resPredios = yield call(getPredios, options);
    const resVendedores = yield call(getVendedores, options);
    yield put({
      type: actions.LIST_FETCH_SUCCESS,
      clientes: resCliente.data,
      periodos: resPeriodos.data,
      predios: resPredios.data,
      vendedores: resVendedores.data,
    });
  });
}



export default function* rootSaga() {
  yield all([
    fork(listRequest),
  ]);
}
