import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  LIST_FETCH_REQUEST: 'LIST_FETCH_REQUEST',
  LIST_FETCH_SUCCESS: 'LIST_FETCH_SUCCESS',
  LIST_FETCH_ERROR: 'LIST_FETCH_ERROR',

  VENTAS_FETCH_REQUEST: 'VENTAS_FETCH_REQUEST',
  VENTAS_FETCH_SUCCESS: 'VENTAS_FETCH_SUCCESS',
  VENTAS_FETCH_ERROR: 'VENTAS_FETCH_ERROR',

  VENTA_CREATE_REQUEST: 'VENTA_CREATE_REQUEST',
  VENTA_CREATE_SUCCESS: 'VENTA_CREATE_SUCCESS',
  VENTA_CREATE_ERROR: 'VENTA_CREATE_ERROR',

  VENTA_UPDATE_REQUEST: 'VENTA_UPDATE_REQUEST',
  VENTA_UPDATE_SUCCESS: 'VENTA_UPDATE_SUCCESS',
  VENTA_UPDATE_ERROR: 'VENTA_UPDATE_ERROR',

  VENTA_PAGOS_UPDATE_REQUEST: 'VENTA_PAGOS_UPDATE_REQUEST',
  VENTA_PAGOS_UPDATE_SUCCESS: 'VENTA_PAGOS_UPDATE_SUCCESS',
  VENTA_PAGOS_UPDATE_ERROR: 'VENTA_PAGOS_UPDATE_ERROR',

  VENTA_UPDATE_MODAL: 'VENTA_UPDATE_MODAL',
  VENTA_SHOW_MODAL: 'VENTA_SHOW_MODAL',
  VENTA_SHOW_MODAL_CLONE: 'VENTA_SHOW_MODAL_CLONE',
  VENTA_HIDE_MODAL: 'VENTA_HIDE_MODAL',

  VENTA_ABRIR_FILTROS_BUSQUEDA: 'VENTA_ABRIR_FILTROS_BUSQUEDA',
  VENTA_CERRAR_FILTROS_BUSQUEDA: 'VENTA_CERRAR_FILTROS_BUSQUEDA',

  LIST_UPDATE_SUCCESS: 'LIST_UPDATE_SUCCESS',

  listVentas: () => ({
    type: action.LIST_FETCH_REQUEST
  }),

  updateList: payload => ({
    type: action.LIST_UPDATE_SUCCESS, payload
  }),

  // Acciones
  searchVentas: (data) => ({
    types: [action.VENTAS_FETCH_REQUEST, action.VENTAS_FETCH_SUCCESS, action.VENTAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/search_ventas`,
        data,
      }
    }
  }),
  addVenta: data => ({
    types: [action.VENTA_CREATE_REQUEST, action.VENTA_CREATE_SUCCESS, action.VENTA_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/ventas`,
        data,
      }
    }
  }),
  editVenta: data => ({
    types: [action.VENTA_UPDATE_REQUEST, action.VENTA_UPDATE_SUCCESS, action.VENTA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/ventas/${data.id}`,
        data,
      }
    }
  }),
  cancelarVenta: data => ({
    types: [action.VENTA_UPDATE_REQUEST, action.VENTA_UPDATE_SUCCESS, action.VENTA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/ventas/${data.id}/cancelar`,
        data,
      }
    }
  }),
  updatePagosVenta: (data, id) => ({
    types: [action.VENTA_PAGOS_UPDATE_REQUEST, action.VENTA_PAGOS_UPDATE_SUCCESS, action.VENTA_PAGOS_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/calendario/venta/${id}`,
        data,
      }
    }
  }),
  deleteVenta: data => ({
    types: [action.VENTA_UPDATE_REQUEST, action.VENTA_UPDATE_SUCCESS, action.VENTA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/ventas/${data.id}`,
      }
    }
  }),
  getVentas: data => ({
    types: [action.VENTAS_FETCH_REQUEST, action.VENTAS_FETCH_SUCCESS, action.VENTAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: '/ventas',
      }
    }
  }),
  getVentasAtrasadas: data => ({
    types: [action.VENTAS_FETCH_REQUEST, action.VENTAS_FETCH_SUCCESS, action.VENTAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: '/ventas/atrasadas',
      }
    }
  }),
  updateModal: payload => ({
    type: action.VENTA_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.VENTA_SHOW_MODAL });
    };
  },
  showModalClone: (payload) => {
    return (dispatch) => {
      dispatch({ type: action.VENTA_SHOW_MODAL_CLONE, payload });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.VENTA_HIDE_MODAL });
    }
  },
  // Abrir filtros
  abrirFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.VENTA_ABRIR_FILTROS_BUSQUEDA });
      dispatch({ type: CERRAR_SIDEBAR });
    }
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.VENTA_CERRAR_FILTROS_BUSQUEDA });
      dispatch({ type: ABRIR_SIDEBAR });
    }
  },
};
export default action;
