import { all, takeEvery, put, call, fork, select } from 'redux-saga/effects';
// import { notification } from 'antd';
import actions from './actions';
import { getClientes, getSeries, getVendedores } from '../../services/ventas';
import { getToken } from '../selectors';

export function* listPagosRequest() {
  yield takeEvery('LIST_PAGOS_FETCH_REQUEST', function*({ payload }) {
    const token = yield select(getToken);
    const options = { token };
    const resCliente = yield call(getClientes, options);
    const resSeries = yield call(getSeries, options);
    const resVendedores = yield call(getVendedores, options);
    yield put({
      type: actions.LIST_PAGOS_FETCH_SUCCESS,
      clientes: resCliente.data,
      vendedores: resVendedores.data,
      series: resSeries.data,
    });
  });
}



export default function* rootSaga() {
  yield all([
    fork(listPagosRequest),
  ]);
}
