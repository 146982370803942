import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
// import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from "@/config/themes";
import AppLocale from "./languageProvider";
import esEs from 'antd/lib/locale-provider/es_ES';
import config, {
  getCurrentLanguage
} from "@/containers/LanguageSwitcher/config";
import { themeConfig } from "@/config";

export default function AppProvider({ children }) {
  const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];
  return (
    <ConfigProvider locale={esEs}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
