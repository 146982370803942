// import clone from 'clone';
import actions from './actions';

const initialState = {
  loading: false,
  isFetching: false,
  error: undefined,
  isFailed: false,
  empresas: [],
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    // Actualizar datos configuracion
    case actions.CONFIG_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFailed: false,
        success: false,
      };
    case actions.CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFailed: false,
        config: payload.data,
      };
    case actions.CONFIG_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isFailed: true
      };

    case actions.CONFIG_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFailed: false,
        success: false,
      };
    case actions.CONFIG_UPDATE_SUCCESS:
      // console.log(payload);
      return {
        ...state,
        isFetching: false,
        isFailed: false,
        success: true,
        notificacion: {
          message: 'Configuracion',
          description: 'Empresa actualizada',
        },
      };
    case actions.CONFIG_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        notificacion: {
          message: 'Error',
          description: 'Error al actualizar',
        },
        isFailed: true
      };

    case actions.EMPRESAS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFailed: false,
        empresas: payload.data.data,
      };
    case actions.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        success: false,
        isFailed: false,
        isFetching: true,
      };
    case actions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        success: true,
        isFetching: false,
        notificacion: payload,
      };
    case actions.UPLOAD_FILE_ERROR:
      return {
        ...state,
        isFailed: true,
        isFetching: false,
        notificacion: payload,
      };

    // CREATE
    case actions.EMPRESA_CREATE_REQUEST:
      return { ...state, isFetching: true, isFailed: false, success: false };

    case actions.EMPRESA_CREATE_SUCCESS:
      return { ...state, empresas: payload.data.empresas, isFetching: false, success: true, notificacion: payload.data.notificacion };

    case actions.EMPRESA_CREATE_ERROR:
      return { ...state, isFetching: false, isFailed: true, notificacion: error.response.data.notificacion };

    // UPDATE
    case actions.EMPRESA_UPDATE_REQUEST:
      return { ...state, isFetching: true, isFailed: false, success: false };

    case actions.EMPRESA_UPDATE_SUCCESS:
      return { ...state, empresas: payload.data.empresas, isFetching: false, success: true, notificacion: payload.data.notificacion };

    case actions.EMPRESA_UPDATE_ERROR:
      return { ...state, isFetching: false, isFailed: true, notificacion: error.response.data.notificacion };

    default:
      return state;
  }
};
