import { language } from "@/config";
import englishLang from "../../image/flag/uk.svg";
import spanishLang from "../../image/flag/spain.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "Inglés",
      icon: englishLang
    },
    {
      languageId: "spanish",
      locale: "es",
      text: "Español",
      icon: spanishLang
    },
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
