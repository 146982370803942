import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  // BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import Loader from '@/components/utility/loader';

const Dashboard = lazy(() => import('@/containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@/containers/Pages/Login/Login')),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector(state => state.auth.token);
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
}
export default function Routes({ history }) {
  // console.log(history);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <ConnectedRouter history={history}>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </ConnectedRouter>
      </Suspense>
    </ErrorBoundary>
  );
}
