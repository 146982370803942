import { notification } from 'antd';
import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  LIST_RECIBOS_FETCH_REQUEST: 'LIST_RECIBOS_FETCH_REQUEST',
  LIST_RECIBOS_FETCH_SUCCESS: 'LIST_RECIBOS_FETCH_SUCCESS',
  LIST_RECIBOS_FETCH_ERROR: 'LIST_RECIBOS_FETCH_ERROR',

  RECIBOS_FETCH_REQUEST: 'RECIBOS_FETCH_REQUEST',
  RECIBOS_FETCH_SUCCESS: 'RECIBOS_FETCH_SUCCESS',
  RECIBOS_FETCH_ERROR: 'RECIBOS_FETCH_ERROR',

  RECIBOS_SEARCH_REQUEST: 'RECIBOS_SEARCH_REQUEST',
  RECIBOS_SEARCH_SUCCESS: 'RECIBOS_SEARCH_SUCCESS',
  RECIBOS_SEARCH_ERROR: 'RECIBOS_SEARCH_ERROR',

  RECIBO_CREATE_REQUEST: 'RECIBO_CREATE_REQUEST',
  RECIBO_CREATE_SUCCESS: 'RECIBO_CREATE_SUCCESS',
  RECIBO_CREATE_ERROR: 'RECIBO_CREATE_ERROR',

  RECIBO_UPDATE_REQUEST: 'RECIBO_UPDATE_REQUEST',
  RECIBO_UPDATE_SUCCESS: 'RECIBO_UPDATE_SUCCESS',
  RECIBO_UPDATE_ERROR: 'RECIBO_UPDATE_ERROR',

  RECIBO_UPDATE_MODAL: 'RECIBO_UPDATE_MODAL',
  RECIBO_SHOW_MODAL: 'RECIBO_SHOW_MODAL',
  RECIBO_SHOW_MODAL_CLONE: 'RECIBO_SHOW_MODAL_CLONE',
  RECIBO_HIDE_MODAL: 'RECIBO_HIDE_MODAL',

  RECIBO_ABRIR_FILTROS_BUSQUEDA: 'RECIBO_ABRIR_FILTROS_BUSQUEDA',
  RECIBO_CERRAR_FILTROS_BUSQUEDA: 'RECIBO_CERRAR_FILTROS_BUSQUEDA',

  RECIBOS_CLIENTE_FETCH_REQUEST: 'RECIBOS_CLIENTE_FETCH_REQUEST',
  RECIBOS_CLIENTE_FETCH_SUCCESS: 'RECIBOS_CLIENTE_FETCH_SUCCESS',
  RECIBOS_CLIENTE_FETCH_ERROR: 'RECIBOS_CLIENTE_FETCH_ERROR',

  RECIBOS_VENTA_FETCH_REQUEST: 'RECIBOS_VENTA_FETCH_REQUEST',
  RECIBOS_VENTA_FETCH_SUCCESS: 'RECIBOS_VENTA_FETCH_SUCCESS',
  RECIBOS_VENTA_FETCH_ERROR: 'RECIBOS_VENTA_FETCH_ERROR',

  RECIBO_SHOW_DRAWER: 'recibos/RECIBO_SHOW_DRAWER',
  LIST_RECIBOS_UPDATE_SUCCESS: 'recibos/LIST_RECIBOS_UPDATE_SUCCESS',

  listRecibos: () => ({
    type: action.LIST_RECIBOS_FETCH_REQUEST,
  }),

  // Acciones
  addRecibo: data => ({
    types: [action.RECIBO_CREATE_REQUEST, action.RECIBO_CREATE_SUCCESS, action.RECIBO_CREATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'POST',
        url: `/recibos`,
        data,
      },
    },
  }),
  editRecibo: data => ({
    types: [action.RECIBO_UPDATE_REQUEST, action.RECIBO_UPDATE_SUCCESS, action.RECIBO_UPDATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'PUT',
        url: `/recibos/${data.id}`,
        data,
      },
    },
  }),
  // cancelarRecibo: data => ({
  //   types: [action.RECIBO_UPDATE_REQUEST, action.RECIBO_UPDATE_SUCCESS, action.RECIBO_UPDATE_ERROR],
  //   payload: {
  //     client: "default",
  //     request: {
  //       method: "POST",
  //       url: `/recibos/${data.id}/cancelar`,
  //       data,
  //     }
  //   }
  // }),
  cancelarRecibo: payload => {
    console.log('payload', payload);
    return dispatch => {
      dispatch({ type: action.RECIBO_UPDATE_REQUEST });
      dispatch({ type: action.RECIBO_UPDATE_SUCCESS, payload });
    };
  },
  deleteRecibo: data => ({
    types: [action.RECIBO_UPDATE_REQUEST, action.RECIBO_UPDATE_SUCCESS, action.RECIBO_UPDATE_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'DELETE',
        url: `/recibos/${data.id}`,
      },
    },
  }),
  getRecibos: data => ({
    types: [action.RECIBOS_FETCH_REQUEST, action.RECIBOS_FETCH_SUCCESS, action.RECIBOS_FETCH_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'GET',
        url: `/recibos?page=${data.page}&limit=${data.limit}`,
      },
    },
  }),
  getRecibosCliente: id => ({
    types: [
      action.RECIBOS_CLIENTE_FETCH_REQUEST,
      action.RECIBOS_CLIENTE_FETCH_SUCCESS,
      action.RECIBOS_CLIENTE_FETCH_ERROR,
    ],
    payload: {
      client: 'default',
      request: {
        method: 'GET',
        url: `/clientes/${id}/recibos`,
      },
    },
  }),
  getRecibosVenta: id => ({
    types: [action.RECIBOS_VENTA_FETCH_REQUEST, action.RECIBOS_VENTA_FETCH_SUCCESS, action.RECIBOS_VENTA_FETCH_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'GET',
        url: `/ventas/${id}/recibos`,
      },
    },
  }),
  searchRecibos: data => ({
    types: [action.RECIBOS_SEARCH_REQUEST, action.RECIBOS_SEARCH_SUCCESS, action.RECIBOS_SEARCH_ERROR],
    payload: {
      client: 'default',
      request: {
        method: 'POST',
        url: '/recibos_search',
        data,
      },
    },
  }),
  updateModal: payload => ({
    type: action.RECIBO_UPDATE_MODAL,
    payload,
  }),
  showModal: () => {
    return dispatch => {
      dispatch({ type: action.RECIBO_SHOW_MODAL });
    };
  },
  showModalClone: payload => {
    return dispatch => {
      dispatch({ type: action.RECIBO_SHOW_MODAL_CLONE, payload });
    };
  },
  hideModal: () => {
    return dispatch => {
      dispatch({ type: action.RECIBO_HIDE_MODAL });
    };
  },
  // Abrir filtros
  abrirFiltros: () => {
    return dispatch => {
      dispatch({ type: action.RECIBO_ABRIR_FILTROS_BUSQUEDA });
      dispatch({ type: CERRAR_SIDEBAR });
    };
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return dispatch => {
      dispatch({ type: action.RECIBO_CERRAR_FILTROS_BUSQUEDA });
      dispatch({ type: ABRIR_SIDEBAR });
    };
  },
  // Mostrar drawer pago
  showDrawer: () => {
    return dispatch => {
      dispatch({ type: action.RECIBO_SHOW_DRAWER });
    };
  },
  updateListRecibos: payload => {
    return dispatch => {
      notification.success(payload.notification);
      dispatch({ type: action.LIST_RECIBOS_UPDATE_SUCCESS, payload });
    };
  },
};
export default action;
