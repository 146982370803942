import Cookies from "js-cookie";
import moment from 'moment';
import { message, notification } from "antd";
import axios from "axios";
import { push } from 'connected-react-router';
import { getToken, clearToken } from '@/lib/helpers/utility';
import AuthHelper from '@/lib/helpers/authHelper';
import config from '@/config'
import { API } from '@/config/api'

const actions = {
  AUTHENTICATE: "auth/AUTHENTICATE",
  SET_CURRENT_USER: "auth/SET_CURRENT_USER",
  LOGIN_REQUEST: "auth/LOGIN_REQUEST",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_ERROR: "auth/LOGIN_ERROR",
  REGISTRO_REQUEST: "auth/REGISTRO_REQUEST",
  REGISTRO_SUCCESS: "auth/REGISTRO_SUCCESS",
  REGISTRO_ERROR: "auth/REGISTRO_ERROR",


  CHECK_AUTHORIZATION: "auth/CHECK_AUTHORIZATION",
  LOGOUT: "auth/LOGOUT",

  RECUPERAR_PASS_REQUEST: "auth/RECUPERAR_PASS_REQUEST",
  RECUPERAR_PASS_SUCCESS: "auth/RECUPERAR_PASS_SUCCESS",
  RECUPERAR_PASS_ERROR: "auth/RECUPERAR_PASS_ERROR",

  RESET_PASS_REQUEST: "auth/RESET_PASS_REQUEST",
  RESET_PASS_SUCCESS: "auth/RESET_PASS_SUCCESS",
  RESET_PASS_ERROR: "auth/RESET_PASS_ERROR",

  UPDATE_USER_REQUEST: "auth/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "auth/UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "auth/UPDATE_USER_ERROR",

  LOGIN_SUCCESS_CHECK: "auth/LOGIN_SUCCESS_CHECK",
  UPDATE_PERFIL_SUCCESS: "auth/UPDATE_PERFIL_SUCCESS",

  /**
   * checkAuthorization
   */
  checkAuthorization: () => dispatch => {
    dispatch({ type: actions.CHECK_AUTHORIZATION });
    const { token } = AuthHelper.checkExpirity(getToken().get('idToken'));
    if (token) {
      // let userFromCookie = Cookies.getJSON("user");
      dispatch({
        type: actions.LOGIN_SUCCESS_CHECK,
        // payload: userFromCookie,
        token: token,
      });
    }
  },

  updatePerfil: data => (dispatch, getState) => {
    axios.put(`${config.apiUrl}/usuarios/${data.id}`, data, { headers: { token: getState().auth.token } }).then(res => {
        dispatch({ type: actions.UPDATE_PERFIL_SUCCESS, payload: res.data.data });
        Cookies.set("user", res.data.data, { expires: 365 });
        notification.success({ message: 'Perfil actualizado', description: res.data.message })
      }).catch(err => notification.error({ message: 'Error', description: err.response.data.err.message }));
  },

  logoutUser: () => dispatch =>
    new Promise(resolve => {
      dispatch({
        type: actions.AUTHENTICATE,
        authenticated: false,
        token: null,
      });
      dispatch({
        type: actions.SET_CURRENT_USER,
        user: {},
        token: null,
      });

      Cookies.remove("user");
      clearToken();
      resolve({});
    }),

  /**
   * Login
   */
  login: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.LOGIN_REQUEST });
    API.post('/login', data).then(res => {
      API.get('/configuracion').then(config => {
        const hrActual = moment().valueOf(); // 9
        const hrInicio = moment(config.data.hrInicio, 'HH').valueOf(); // 6
        const hrFin = moment(config.data.hrFin, 'HH').valueOf(); // 7
        // console.log('hrActual', hrActual);
        // console.log('hrInicio', hrInicio);
        // console.log('hrFin', hrFin);
        if (res.data.data.tipo === 'admin') {
          // console.log('dejar entrar');
          dispatch({ type: actions.LOGIN_SUCCESS, payload: res.data.data, token: res.data.token });
          localStorage.setItem('id_token', res.data.token);
          dispatch(push("/dashboard"));
          message.success('Bienvenido');
        } else {
          if (hrActual > hrInicio && hrActual < hrFin) {
            // console.log('Se encuentra en ese rango');
            dispatch({ type: actions.LOGIN_SUCCESS, payload: res.data.data, token: res.data.token });
            localStorage.setItem('id_token', res.data.token);
            dispatch(push("/dashboard"));
            message.success('Bienvenido');
          } else {
            message.error('No se encuentra en horario de acceso');
          }
        }

      })
    }).catch(err => {
        // console.log('Error', err.err.message);
        notification.error({
          message: 'Error',
          description: err.err.message,
        });
        dispatch({ type: actions.LOGIN_ERROR });
      });
  },

  /**
   * Registro
   */
  register: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.REGISTRO_REQUEST });
    axios.post(`${config.apiUrl}/registro`, data).then(res => {
      // console.log(res);
      dispatch({ type: actions.REGISTRO_SUCCESS, payload: res.data.data, token: res.data.token });
      localStorage.setItem('id_token', res.data.token);
      Cookies.set("user", res.data.data, { expires: 365 });
      if (res.data.data.tipo === 'developer') {
        history.push("/my-account/my-resume");
      } else {
        history.push("/my-account");
      }
      message.success('Welcome');
    })
    .catch(err => {
      notification.error(err.response.data.err.notification);
      dispatch({ type: actions.REGISTRO_ERROR, payload: {} });
    });
  },

  /**
   * Update
   */
  updateUser: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.put(`${config.apiUrl}/usuarios/${data.id}`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log(res);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      // history.push("/my-account/skills");
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * Update Idiomas
   */
  updateIdiomas: (data, id) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.post(`${config.apiUrl}/developer/${id}/idiomas`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log('Guardar usuario', res.data.data);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * Update Estudios
   */
  updateEstudios: (data, id) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.post(`${config.apiUrl}/developer/${id}/estudios`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log(res);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * Update Experiencias
   */
  updateExperiencias: (data, id) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.post(`${config.apiUrl}/developer/${id}/experiencias`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log(res);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * Update Habilidades
   */
  updateHabilidades: (data, id) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.post(`${config.apiUrl}/developer/${id}/habilidades`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log(res);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * Update Archivos
   */
  updateArchivos: (data, id) => (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_USER_REQUEST });
    axios.post(`${config.apiUrl}/developer/${id}/archivos`, data, {
      headers: { token: getState().auth.token }
    }).then(res => {
      // console.log(res);
      dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data.data });
      Cookies.set("user", res.data.data, { expires: 365 });
      message.success(res.data.message);
    })
    .catch(err => {
      message.error('Error');
      dispatch({ type: actions.UPDATE_USER_ERROR, payload: {} });
    });
  },

  /**
   * recuperar pass
   */
  recuperarPass: data => (dispatch, getState) => {
    dispatch({ type: actions.RECUPERAR_PASS_REQUEST });
    axios.post(`${config.apiUrl}/auth/recuperar`, data).then(res => {
        dispatch({ type: actions.RECUPERAR_PASS_SUCCESS, payload: res.data.data });
      }).catch(err => message.error(err.response.data.err.message));
  },

  /**
   * reset pass email
   */
  resetPass: data => (dispatch, getState) => {
    dispatch({ type: actions.RESET_PASS_REQUEST });
    axios.post(`${config.apiUrl}/auth/reset`, data).then(res => {
        dispatch({ type: actions.RESET_PASS_SUCCESS, payload: res.data.data });
      }).catch(err => {
        dispatch({ type: actions.RESET_PASS_ERROR });
        message.error(err.response.data.err.message)
      });
  },

  /**
   * change pass my-account
   */
  changePass: data => (dispatch, getState) => {
    dispatch({ type: actions.RESET_PASS_REQUEST });
    axios.post(`${config.apiUrl}/auth/reset_password`, data, { headers: { token: getState().auth.token } }).then(res => {
        dispatch({ type: actions.RESET_PASS_SUCCESS });
        message.success(res.data.message)
      }).catch(err => {
        dispatch({ type: actions.RESET_PASS_ERROR });
        message.error(err.response.data.err.message)
      });
  },
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
