const action = {
  PERIODO_CREATE_REQUEST: 'PERIODO_CREATE_REQUEST',
  PERIODO_CREATE_SUCCESS: 'PERIODO_CREATE_SUCCESS',
  PERIODO_CREATE_ERROR: 'PERIODO_CREATE_ERROR',

  PERIODO_UPDATE_REQUEST: 'PERIODO_UPDATE_REQUEST',
  PERIODO_UPDATE_SUCCESS: 'PERIODO_UPDATE_SUCCESS',
  PERIODO_UPDATE_ERROR: 'PERIODO_UPDATE_ERROR',

  PERIODOS_FETCH_REQUEST: 'PERIODOS_FETCH_REQUEST',
  PERIODOS_FETCH_SUCCESS: 'PERIODOS_FETCH_SUCCESS',
  PERIODOS_FETCH_ERROR: 'PERIODOS_FETCH_ERROR',

  PERIODO_UPDATE_MODAL: 'PERIODO_UPDATE_MODAL',
  PERIODO_SHOW_MODAL: 'PERIODO_SHOW_MODAL',
  PERIODO_HIDE_MODAL: 'PERIODO_HIDE_MODAL',

  PERIODO_DELETE_REQUEST: 'PERIODO_DELETE_REQUEST',
  PERIODO_DELETE_SUCCESS: 'PERIODO_DELETE_SUCCESS',
  PERIODO_DELETE_ERROR: 'PERIODO_DELETE_ERROR',

  // Acciones
  addPeriodo: data => ({
    types: [action.PERIODO_CREATE_REQUEST, action.PERIODO_CREATE_SUCCESS, action.PERIODO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/periodos`,
        data,
      }
    }
  }),
  editPeriodo: data => ({
    types: [action.PERIODO_UPDATE_REQUEST, action.PERIODO_UPDATE_SUCCESS, action.PERIODO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/periodos/${data.id}`,
        data,
      }
    }
  }),
  deletePeriodo: data => ({
    types: [action.PERIODO_DELETE_REQUEST, action.PERIODO_DELETE_SUCCESS, action.PERIODO_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/periodos/${data.id}`,
      }
    }
  }),
  getPeriodos: () => ({
    types: [action.PERIODOS_FETCH_REQUEST, action.PERIODOS_FETCH_SUCCESS, action.PERIODOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/periodos`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.PERIODO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PERIODO_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PERIODO_HIDE_MODAL });
    }
  },
};
export default action;
