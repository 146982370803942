// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  filtrosBusqueda: false,
  error: false,
  currentItem: {
    socios: []
  },
  options: {},
};

export default function ventasReducer(state = init, action) {
  const { payload, error } = action;
  switch (action.type) {
    // FETCH
    case actions.LIST_FETCH_REQUEST:
      return {...state, isFetching: true, success: false };

    case actions.LIST_FETCH_SUCCESS:
      return {
        ...state,
        options: {
          periodos: action.periodos,
          clientes: action.clientes,
          predios: action.predios,
          vendedores: action.vendedores,
        },
        isFetching: false,
        success: true
      };
    case actions.LIST_FETCH_ERROR:
      return { ...state, isFetching: false, success: false };

    // Actualizar la lista al actualizar archivos
    case actions.LIST_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.id ? payload : x),
      };

    // FETCH
    case actions.VENTAS_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.VENTAS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
        success: true
      };
    case actions.VENTAS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false, success: false };

    // CREATE
    case actions.VENTA_CREATE_REQUEST:
      return { ...state, isFetching: true, error: false };

    case actions.VENTA_CREATE_SUCCESS:
      return {
        ...state,
        // list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
        visible: false,
      };
    case actions.VENTA_CREATE_ERROR:
      return { ...state, isFetching: false, error: true, notification: error.response.data.err };

    // UPDATE
    case actions.VENTA_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.VENTA_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
        // list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
        currentItem: { socios: [] },
      };

    case actions.VENTA_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE PAGOS DE UNA VENTA
    case actions.VENTA_PAGOS_UPDATE_SUCCESS:
      return { ...state, currentItem: payload.data.data, modalVisible: false };

    case actions.VENTA_PAGOS_UPDATE_ERROR:
      return { ...state, error: true };

    case actions.VENTA_POBLACION_UPDATE_SUCCESS:
      return {
        ...state,
        currentItem: payload.data.currentItem,
        list: payload.data.data,
        isLoading: false,
      };
    case actions.VENTA_POBLACION_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.VENTA_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // MODAL
    case actions.VENTA_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.VENTA_SHOW_MODAL_CLONE:
      return { ...state, visible: !state.visible, currentItem: action.payload, };

    case actions.VENTA_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    case actions.VENTA_ABRIR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: true };

    case actions.VENTA_CERRAR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: false };

    default:
      return state;
  }
}
