import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'react-router-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { multiClientMiddleware } from "redux-axios-middleware";
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../redux/reducers';
import rootSaga from '../redux/sagas';
import clients from "./clients";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const reducer = persistReducer(
  {
    key: 'rrsb', // key is required
    storage, // storage is now required
    whitelist: ['auth'],
  },
  combineReducers({
    ...rootReducer,
    router: connectRouter(history),
    form: formReducer
  })
);

/* simplified React Promise Middleware */
function promiseMiddleware({dispatch}) {
  function isPromise(val) {
    return val && typeof val.then === 'function';
  }

  return next => action => {
    return isPromise(action.payload)
      ? action.payload.then(
          result => dispatch({...action, payload: result}),
          error => dispatch({...action, payload: error, error: true })
        )
      : next(action);
  };
}

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      ({ getState, dispatch }, req) => {
        if (getState().auth.token) {
          req.headers["token"] = getState().auth.token;
        }
        return req;
      }
    ],
    response: [
      ({ getState, dispatch }, response) => {
        return response;
      }
    ]
  }
};

const middlewares = [thunk, sagaMiddleware, routeMiddleware, promiseMiddleware, multiClientMiddleware(clients, axiosMiddlewareOptions),];

const enhancers = [];
/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({ collapsed: true }));
}

// Dev tools are helpful
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(createStore);

  const store = createStoreWithMiddleware(reducer, initialState);

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();
export { store, persistor, history };
