// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
};

export default function solicitudReducer(state = init, action) {
  const { payload, error } = action;
  switch (action.type) {
    // FETCH
    case actions.VENDEDORS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.VENDEDORS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
      };
    case actions.VENDEDORS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };

    // CREATE
    case actions.VENDEDOR_CREATE_REQUEST:
      return { ...state, cargando: true };

    case actions.VENDEDOR_CREATE_SUCCESS:
      return {
        ...state,
        list: [payload.data.data, ...state.list],
        cargando: false,
        modalVisible: false,
        success: true,
      };
    case actions.VENDEDOR_CREATE_ERROR:
      return { ...state, cargando: false, error: true, notification: error.response.data.err.notification  };

    // UPDATE
    case actions.VENDEDOR_UPDATE_REQUEST:
      return { ...state, cargando: true };

    case actions.VENDEDOR_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
        cargando: false,
        modalVisible: false,
      };
    case actions.VENDEDOR_UPDATE_ERROR:
      return { ...state, cargando: false };

    // UPDATE
    case actions.VENDEDOR_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // UPDATE
    case actions.VENDEDOR_DELETE_REQUEST:
      return { ...state, cargando: true };

    case actions.VENDEDOR_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== payload.data.data.id),
        cargando: false,
        modalVisible: false,
      };
    case actions.VENDEDOR_DELETE_ERROR:
      return { ...state, list: [], cargando: false };

    // MODAL
    case actions.VENDEDOR_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.VENDEDOR_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    default:
      return state;
  }
}
