// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  filtrosBusqueda: false,
  error: false,
  currentItem: {
    socios: []
  },
  options: {
    clientes: [],
    series: [],
    vendedores: [],
  },
};

export default function pagosReducer(state = init, action) {
  const { payload, error } = action;
  switch (action.type) {
    // FETCH
    case actions.LIST_PAGOS_FETCH_REQUEST:
      return {...state, cargando: true, success: false };

    case actions.LIST_PAGOS_FETCH_SUCCESS:
      return {
        ...state,
        options: {
          clientes: action.clientes,
          series: action.series,
          vendedores: action.vendedores,
        },
        cargando: false,
        success: true
      };
    case actions.LIST_PAGOS_FETCH_ERROR:
      return { ...state, cargando: false, success: false };

    // FETCH
    case actions.PAGOS_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.PAGOS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data,
        pagination: payload.data.pagination,
        isFetching: false,
        success: true
      };
    case actions.PAGOS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false, success: false };

    // LISTADO DE PAGOS DE UN CLIENTE
    case actions.PAGOS_CLIENTE_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.PAGOS_CLIENTE_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data,
        isFetching: false,
        success: true
      };
    case actions.PAGOS_CLIENTE_FETCH_ERROR:
      return { ...state, isFetching: false, success: false };

    // LISTADO DE PAGOS DE UNA VENTA
    case actions.PAGOS_VENTA_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.PAGOS_VENTA_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data,
        isFetching: false,
        success: true
      };
    case actions.PAGOS_VENTA_FETCH_ERROR:
      return { ...state, isFetching: false, success: false };

    // SEARCH PAGOS
    case actions.PAGOS_SEARCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.PAGOS_SEARCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data,
        pagination: payload.data.pagination,
        isFetching: false,
        success: true
      };
    case actions.PAGOS_SEARCH_ERROR:
      return { ...state, isFetching: false, success: false };

    // CREATE
    case actions.PAGO_CREATE_REQUEST:
      return { ...state, isFetching: true, error: false };

    case actions.PAGO_CREATE_SUCCESS:
      return {
        ...state,
        // list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
        visible: false,
      };
    case actions.PAGO_CREATE_ERROR:
      return { ...state, isFetching: false, error: true, notification: error.response.data.err };

    // UPDATE
    case actions.PAGO_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PAGO_UPDATE_SUCCESS:
      console.log('pauy', payload);
      return {
        ...state,
        list: state.list.map(x => x.id === payload.id ? payload : x),
        // list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
        currentItem: { socios: [] },
      };
    // case actions.PAGO_UPDATE_SUCCESS:
    //   return {
    //     ...state,
    //     list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
    //     // list: payload.data.data || [],
    //     isFetching: false,
    //     modalVisible: false,
    //     currentItem: { socios: [] },
    //   };
    case actions.PAGO_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };


    // UPDATE
    case actions.PAGO_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // DRAWER
    case actions.PAGO_SHOW_DRAWER:
      return { ...state, drawerVisible: !state.drawerVisible };

    // UPDATE LISTADO
    case actions.LIST_PAGOS_UPDATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, payload.data],
        drawerVisible: false,
      };

    // MODAL
    case actions.PAGO_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.PAGO_SHOW_MODAL_CLONE:
      return { ...state, visible: !state.visible, currentItem: action.payload, };

    case actions.PAGO_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    case actions.PAGO_ABRIR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: true };

    case actions.PAGO_CERRAR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: false };

    default:
      return state;
  }
}
