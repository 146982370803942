import auth from './auth/reducer';
import app from './app/reducer';
import languageSwitcher from './languageSwitcher/reducer';
import search from './search/reducer';
import clientes from './clientes/reducer';
import predios from './predios/reducer';
import socios from './socios/reducer';
import vendedores from './vendedores/reducer';
import periodos from './periodos/reducer';
import series from './series/reducer';
import ventas from './ventas/reducer';
import pagos from './pagos/reducer';
import recibos from './recibos/reducer';
import config from './config/reducer';

export default {
  auth,
  app,
  clientes,
  search,
  languageSwitcher,
  predios,
  socios,
  periodos,
  series,
  vendedores,
  ventas,
  pagos,
  recibos,
  config,
};
