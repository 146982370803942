const action = {
  VENDEDOR_CREATE_REQUEST: 'VENDEDOR_CREATE_REQUEST',
  VENDEDOR_CREATE_SUCCESS: 'VENDEDOR_CREATE_SUCCESS',
  VENDEDOR_CREATE_ERROR: 'VENDEDOR_CREATE_ERROR',

  VENDEDOR_UPDATE_REQUEST: 'VENDEDOR_UPDATE_REQUEST',
  VENDEDOR_UPDATE_SUCCESS: 'VENDEDOR_UPDATE_SUCCESS',
  VENDEDOR_UPDATE_ERROR: 'VENDEDOR_UPDATE_ERROR',

  VENDEDORS_FETCH_REQUEST: 'VENDEDORS_FETCH_REQUEST',
  VENDEDORS_FETCH_SUCCESS: 'VENDEDORS_FETCH_SUCCESS',
  VENDEDORS_FETCH_ERROR: 'VENDEDORS_FETCH_ERROR',

  VENDEDOR_UPDATE_MODAL: 'VENDEDOR_UPDATE_MODAL',
  VENDEDOR_SHOW_MODAL: 'VENDEDOR_SHOW_MODAL',
  VENDEDOR_HIDE_MODAL: 'VENDEDOR_HIDE_MODAL',

  VENDEDOR_DELETE_REQUEST: 'VENDEDOR_DELETE_REQUEST',
  VENDEDOR_DELETE_SUCCESS: 'VENDEDOR_DELETE_SUCCESS',
  VENDEDOR_DELETE_ERROR: 'VENDEDOR_DELETE_ERROR',

  // Acciones
  addVendedor: data => ({
    types: [action.VENDEDOR_CREATE_REQUEST, action.VENDEDOR_CREATE_SUCCESS, action.VENDEDOR_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/vendedores`,
        data,
      }
    }
  }),
  editVendedor: data => ({
    types: [action.VENDEDOR_UPDATE_REQUEST, action.VENDEDOR_UPDATE_SUCCESS, action.VENDEDOR_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/vendedores/${data.id}`,
        data,
      }
    }
  }),
  deleteVendedor: data => ({
    types: [action.VENDEDOR_DELETE_REQUEST, action.VENDEDOR_DELETE_SUCCESS, action.VENDEDOR_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/vendedores/${data.id}`,
      }
    }
  }),
  getVendedors: () => ({
    types: [action.VENDEDORS_FETCH_REQUEST, action.VENDEDORS_FETCH_SUCCESS, action.VENDEDORS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/vendedores`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.VENDEDOR_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.VENDEDOR_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.VENDEDOR_HIDE_MODAL });
    }
  },
};
export default action;
