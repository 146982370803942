import fetch from 'isomorphic-fetch';
// import { notification } from 'antd';

// const codeMessage = {
//   200: 'El servidor devolvió correctamente los datos solicitados.',
//   201: 'Los datos nuevos o modificados son exitosos.',
//   202: 'Una solicitud ha ingresado a la cola de fondo (tarea asíncrona).',
//   204: 'Eliminar datos con éxito.',
//   400: 'La solicitud se emitió con un error. El servidor no creó ni modificó datos.',
//   401: 'El usuario no tiene permiso (token, nombre de usuario, contraseña).',
//   403: 'El usuario está autorizado, pero el acceso está prohibido.',
//   404: 'La solicitud se emitió para un registro inexistente y el servidor no realizó la operación.',
//   406: 'El formato solicitado no está disponible.',
//   410: 'El recurso solicitado se elimina permanentemente y ya no estará disponible.',
//   422: 'Se produjo un error de validación al crear un objeto.',
//   500: 'Se produjo un error en el servidor. Compruebe el servidor.',
//   502: 'Mala puerta de enlace.',
//   503: 'El servicio no está disponible y el servidor está temporalmente sobrecargado o mantenido.',
//   504: 'Gateway agotó el tiempo de espera.',
// };
// function checkStatus(response) {
//   if (response.status >= 200 && response.status < 300) {
//     return response.json();
//   }
//   const errortext = codeMessage[response.status] || response.statusText;
//   notification.error({
//     message: `Error de solicitud ${response.status}`,
//     description: errortext,
//   });
//   const error = new Error(errortext);
//   error.name = response.status;
//   error.response = response;
//   throw error;
// }

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options, token) {
  // console.log(token);
  const newOptions = { ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        token,
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    }
  }
  if (newOptions.method === 'GET' || newOptions.method === 'DELETE') {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      token,
      ...newOptions.headers,
    };
  }
  return fetch(url, newOptions)
    .then(response => response.json())
    .catch((error) => {
      if (error) {
        console.error('Error', error);
        // notification.error({
        //   description: error,
        // });
      }
      return error;
    });
}
