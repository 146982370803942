import axios from "axios/index";
import config from "@/config";

// Api default
const apiClient = baseUrl =>
  axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
  });

const clients = {
  default: {
    client: apiClient(config.apiUrl)
  }
};

export default clients;
