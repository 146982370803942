import { notification } from 'antd';
import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  LIST_PAGOS_FETCH_REQUEST: 'LIST_PAGOS_FETCH_REQUEST',
  LIST_PAGOS_FETCH_SUCCESS: 'LIST_PAGOS_FETCH_SUCCESS',
  LIST_PAGOS_FETCH_ERROR: 'LIST_PAGOS_FETCH_ERROR',

  PAGOS_FETCH_REQUEST: 'PAGOS_FETCH_REQUEST',
  PAGOS_FETCH_SUCCESS: 'PAGOS_FETCH_SUCCESS',
  PAGOS_FETCH_ERROR: 'PAGOS_FETCH_ERROR',

  PAGOS_SEARCH_REQUEST: 'PAGOS_SEARCH_REQUEST',
  PAGOS_SEARCH_SUCCESS: 'PAGOS_SEARCH_SUCCESS',
  PAGOS_SEARCH_ERROR: 'PAGOS_SEARCH_ERROR',

  PAGO_CREATE_REQUEST: 'PAGO_CREATE_REQUEST',
  PAGO_CREATE_SUCCESS: 'PAGO_CREATE_SUCCESS',
  PAGO_CREATE_ERROR: 'PAGO_CREATE_ERROR',

  PAGO_UPDATE_REQUEST: 'PAGO_UPDATE_REQUEST',
  PAGO_UPDATE_SUCCESS: 'PAGO_UPDATE_SUCCESS',
  PAGO_UPDATE_ERROR: 'PAGO_UPDATE_ERROR',

  PAGO_UPDATE_MODAL: 'PAGO_UPDATE_MODAL',
  PAGO_SHOW_MODAL: 'PAGO_SHOW_MODAL',
  PAGO_SHOW_MODAL_CLONE: 'PAGO_SHOW_MODAL_CLONE',
  PAGO_HIDE_MODAL: 'PAGO_HIDE_MODAL',

  PAGO_ABRIR_FILTROS_BUSQUEDA: 'PAGO_ABRIR_FILTROS_BUSQUEDA',
  PAGO_CERRAR_FILTROS_BUSQUEDA: 'PAGO_CERRAR_FILTROS_BUSQUEDA',

  PAGOS_CLIENTE_FETCH_REQUEST: 'PAGOS_CLIENTE_FETCH_REQUEST',
  PAGOS_CLIENTE_FETCH_SUCCESS: 'PAGOS_CLIENTE_FETCH_SUCCESS',
  PAGOS_CLIENTE_FETCH_ERROR: 'PAGOS_CLIENTE_FETCH_ERROR',

  PAGOS_VENTA_FETCH_REQUEST: 'PAGOS_VENTA_FETCH_REQUEST',
  PAGOS_VENTA_FETCH_SUCCESS: 'PAGOS_VENTA_FETCH_SUCCESS',
  PAGOS_VENTA_FETCH_ERROR: 'PAGOS_VENTA_FETCH_ERROR',

  PAGO_SHOW_DRAWER: 'pagos/PAGO_SHOW_DRAWER',
  LIST_PAGOS_UPDATE_SUCCESS: 'pagos/LIST_PAGOS_UPDATE_SUCCESS',

  listPagos: () => ({
    type: action.LIST_PAGOS_FETCH_REQUEST
  }),

  // Acciones
  addPago: data => ({
    types: [action.PAGO_CREATE_REQUEST, action.PAGO_CREATE_SUCCESS, action.PAGO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/pagos`,
        data,
      }
    }
  }),
  editPago: data => ({
    types: [action.PAGO_UPDATE_REQUEST, action.PAGO_UPDATE_SUCCESS, action.PAGO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/pagos/${data.id}`,
        data,
      }
    }
  }),
  // cancelarPago: data => ({
  //   types: [action.PAGO_UPDATE_REQUEST, action.PAGO_UPDATE_SUCCESS, action.PAGO_UPDATE_ERROR],
  //   payload: {
  //     client: "default",
  //     request: {
  //       method: "POST",
  //       url: `/pagos/${data.id}/cancelar`,
  //       data,
  //     }
  //   }
  // }),
  cancelarPago: (payload) => {
    console.log('payload', payload)
    return (dispatch) => {
      dispatch({ type: action.PAGO_UPDATE_REQUEST });
      dispatch({ type: action.PAGO_UPDATE_SUCCESS, payload });
    }
  },
  deletePago: data => ({
    types: [action.PAGO_UPDATE_REQUEST, action.PAGO_UPDATE_SUCCESS, action.PAGO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/pagos/${data.id}`,
      }
    }
  }),
  getPagos: data => ({
    types: [action.PAGOS_FETCH_REQUEST, action.PAGOS_FETCH_SUCCESS, action.PAGOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/pagos?page=${data.page}&limit=${data.limit}`,
      }
    }
  }),
  getPagosCliente: id => ({
    types: [action.PAGOS_CLIENTE_FETCH_REQUEST, action.PAGOS_CLIENTE_FETCH_SUCCESS, action.PAGOS_CLIENTE_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/clientes/${id}/pagos`,
      }
    }
  }),
  getPagosVenta: id => ({
    types: [action.PAGOS_VENTA_FETCH_REQUEST, action.PAGOS_VENTA_FETCH_SUCCESS, action.PAGOS_VENTA_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/ventas/${id}/pagos`,
      }
    }
  }),
  searchPagos: data => ({
    types: [action.PAGOS_SEARCH_REQUEST, action.PAGOS_SEARCH_SUCCESS, action.PAGOS_SEARCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: '/pagos_search',
        data,
      }
    }
  }),
  updateModal: payload => ({
    type: action.PAGO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_SHOW_MODAL });
    };
  },
  showModalClone: (payload) => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_SHOW_MODAL_CLONE, payload });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_HIDE_MODAL });
    }
  },
  // Abrir filtros
  abrirFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_ABRIR_FILTROS_BUSQUEDA });
      dispatch({ type: CERRAR_SIDEBAR });
    }
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_CERRAR_FILTROS_BUSQUEDA });
      dispatch({ type: ABRIR_SIDEBAR });
    }
  },
  // Mostrar drawer pago
  showDrawer: () => {
    return (dispatch) => {
      dispatch({ type: action.PAGO_SHOW_DRAWER });
    };
  },
  updateListPagos: payload => {
    return (dispatch) => {
      notification.success(payload.notification);
      dispatch({ type: action.LIST_PAGOS_UPDATE_SUCCESS, payload });
    };
  },
};
export default action;
