import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import vetaSagas from './ventas/saga';
import pagoSagas from './pagos/saga';
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    vetaSagas(),
    pagoSagas(),
  ]);
}
