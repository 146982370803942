import actions from './actions';

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  error: false,
  loading: false,
  token: null,
  create: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {

    // Actualizar datos de perfil usuario
    case actions.UPDATE_PERFIL_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };

    case actions.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated
      };

    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
        token: action.token,
      };

    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isAuthenticated: false,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        isAuthenticated: true,
        token: action.token,
        tipo: action.payload.tipo,
      };
    case actions.LOGIN_SUCCESS_CHECK:
      return {
        ...state,
        token: action.token,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };

    case actions.REGISTRO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actions.REGISTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        isAuthenticated: true,
        token: action.token,
        tipo: action.payload.tipo,
      };
    case actions.REGISTRO_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        notificacion: payload,
      };

    case actions.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        // isAuthenticated: true,
        // token: action.token,
      };
    case actions.UPDATE_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        notificacion: payload,
      };

    case actions.RECUPERAR_PASS_REQUEST:
      return { ...state, loading: true, success: false };
    case actions.RECUPERAR_PASS_SUCCESS:
      return { ...state, loading: false, success: true };

    case actions.RESET_PASS_REQUEST:
      return { ...state, loading: true, success: false };
    case actions.RESET_PASS_SUCCESS:
      return { ...state, loading: false, success: true };
    case actions.RESET_PASS_ERROR:
      return { ...state, loading: false, success: false };


    case actions.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
