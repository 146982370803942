const action = {
  SEARCH_QUERY_REQUEST: "search/SEARCH_QUERY_REQUEST",

  searchJobs: (payload, history) => dispatch => {
    dispatch({ type: action.SEARCH_QUERY_REQUEST, payload });
    history.push('/jobs')
  },

};
export default action;
