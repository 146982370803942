const action = {
  SOCIO_CREATE_REQUEST: 'SOCIO_CREATE_REQUEST',
  SOCIO_CREATE_SUCCESS: 'SOCIO_CREATE_SUCCESS',
  SOCIO_CREATE_ERROR: 'SOCIO_CREATE_ERROR',

  SOCIO_UPDATE_REQUEST: 'SOCIO_UPDATE_REQUEST',
  SOCIO_UPDATE_SUCCESS: 'SOCIO_UPDATE_SUCCESS',
  SOCIO_UPDATE_ERROR: 'SOCIO_UPDATE_ERROR',

  SOCIOS_FETCH_REQUEST: 'SOCIOS_FETCH_REQUEST',
  SOCIOS_FETCH_SUCCESS: 'SOCIOS_FETCH_SUCCESS',
  SOCIOS_FETCH_ERROR: 'SOCIOS_FETCH_ERROR',

  SOCIO_UPDATE_MODAL: 'SOCIO_UPDATE_MODAL',
  SOCIO_SHOW_MODAL: 'SOCIO_SHOW_MODAL',
  SOCIO_HIDE_MODAL: 'SOCIO_HIDE_MODAL',

  SOCIO_DELETE_REQUEST: 'SOCIO_DELETE_REQUEST',
  SOCIO_DELETE_SUCCESS: 'SOCIO_DELETE_SUCCESS',
  SOCIO_DELETE_ERROR: 'SOCIO_DELETE_ERROR',

  // Acciones
  addSocio: data => ({
    types: [action.SOCIO_CREATE_REQUEST, action.SOCIO_CREATE_SUCCESS, action.SOCIO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/socios`,
        data,
      }
    }
  }),
  editSocio: data => ({
    types: [action.SOCIO_UPDATE_REQUEST, action.SOCIO_UPDATE_SUCCESS, action.SOCIO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/socios/${data.id}`,
        data,
      }
    }
  }),
  deleteSocio: data => ({
    types: [action.SOCIO_DELETE_REQUEST, action.SOCIO_DELETE_SUCCESS, action.SOCIO_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/socios/${data.id}`,
      }
    }
  }),
  getSocios: () => ({
    types: [action.SOCIOS_FETCH_REQUEST, action.SOCIOS_FETCH_SUCCESS, action.SOCIOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/socios`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.SOCIO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.SOCIO_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.SOCIO_HIDE_MODAL });
    }
  },
};
export default action;
