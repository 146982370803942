export default {
  apiUrl: 'https://bienes.pinedapp.com/api',
  // apiUrl: 'http://localhost:4001/api',
};

// const urlCliente = 'http://localhost';
const urlCliente = 'https://brcruz.com';

const siteConfig = {
  siteName: 'BienesRaices',
  siteIcon: 'ion-flash',
  footerText: 'BienesRaices ©2019',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'spanish';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};
const PAGE_SIZE = 9;

export { language, themeConfig, siteConfig, jwtConfig, urlCliente, PAGE_SIZE };
