import { post } from 'axios';
// import { message } from 'antd';
import url from '@/config';
const action = {
  CONFIG_FETCH_REQUEST: "CONFIG_FETCH_REQUEST",
  CONFIG_FETCH_SUCCESS: "CONFIG_FETCH_SUCCESS",
  CONFIG_FETCH_ERROR: "CONFIG_FETCH_ERROR",

  CONFIG_UPDATE_REQUEST: "CONFIG_UPDATE_REQUEST",
  CONFIG_UPDATE_SUCCESS: "CONFIG_UPDATE_SUCCESS",
  CONFIG_UPDATE_ERROR: "CONFIG_UPDATE_ERROR",

  EMPRESAS_FETCH_REQUEST: "EMPRESAS_FETCH_REQUEST",
  EMPRESAS_FETCH_SUCCESS: "EMPRESAS_FETCH_SUCCESS",
  EMPRESAS_FETCH_ERROR: "EMPRESAS_FETCH_ERROR",

  EMPRESA_CREATE_REQUEST: "EMPRESA_CREATE_REQUEST",
  EMPRESA_CREATE_SUCCESS: "EMPRESA_CREATE_SUCCESS",
  EMPRESA_CREATE_ERROR: "EMPRESA_CREATE_ERROR",

  EMPRESA_UPDATE_REQUEST: "EMPRESA_UPDATE_REQUEST",
  EMPRESA_UPDATE_SUCCESS: "EMPRESA_UPDATE_SUCCESS",
  EMPRESA_UPDATE_ERROR: "EMPRESA_UPDATE_ERROR",

  EMPRESA_DELETE_REQUEST: "EMPRESA_DELETE_REQUEST",
  EMPRESA_DELETE_SUCCESS: "EMPRESA_DELETE_SUCCESS",
  EMPRESA_DELETE_ERROR: "EMPRESA_DELETE_ERROR",

  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_ERROR: "UPLOAD_FILE_ERROR",

  getConfiguracion: () => ({
    types: [action.CONFIG_FETCH_REQUEST, action.CONFIG_FETCH_SUCCESS, action.CONFIG_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: '/configuracion',
      }
    }
  }),
  updateConfig: data => ({
    types: [action.CONFIG_UPDATE_REQUEST, action.CONFIG_UPDATE_SUCCESS, action.CONFIG_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: '/configuracion',
        data
      }
    }
  }),
  getEmpresas: () => ({
    types: [action.EMPRESAS_FETCH_REQUEST, action.EMPRESAS_FETCH_SUCCESS, action.EMPRESAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: '/empresas',
      }
    }
  }),
  createEmpresa: data => ({
    types: [action.EMPRESA_CREATE_REQUEST, action.EMPRESA_CREATE_SUCCESS, action.EMPRESA_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: '/empresas',
        data
      }
    }
  }),
  updateEmpresa: data => ({
    types: [action.EMPRESA_UPDATE_REQUEST, action.EMPRESA_UPDATE_SUCCESS, action.EMPRESA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/empresas/${data.id}`,
        data
      }
    }
  }),
  deleteEmpresa: id => ({
    types: [action.EMPRESA_DELETE_REQUEST, action.EMPRESA_DELETE_SUCCESS, action.EMPRESA_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/empresas/${id}`,
      }
    }
  }),
  uploadFile: (data, tipo) => dispatch => {
    dispatch({ type: action.UPLOAD_FILE_REQUEST });
    // console.log('data', data);
    let formData = new FormData();
    formData.append('password', data.password);
    formData.append('confirmpassword', data.confirmpassword);
    formData.append('certificado', data.certificado);
    formData.append('llave', data.llave);
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    post(`${url.apiUrl}/empresas/upload_file/${tipo}`, formData, config).then(res => {
			// console.log(res);
      // message.success(res.data.data.MessageStatus);
      dispatch({ type: action.UPLOAD_FILE_SUCCESS, payload: res.data.notificacion });
    }).catch(err => {
        // console.log(err.response.data);
        dispatch({ type: action.UPLOAD_FILE_ERROR, payload: err.response.data.notificacion });
    });

  },

};
export default action;
