// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  filtrosBusqueda: false,
  error: false,
  currentItem: {
    socios: []
  }
};

export default function solicitudReducer(state = init, action) {
  const { payload, error } = action;
  switch (action.type) {
    // FETCH
    case actions.PREDIOS_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.PREDIOS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
        success: true
      };
    case actions.PREDIOS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false, success: false };

    // CREATE
    case actions.PREDIO_CREATE_REQUEST:
      return { ...state, isFetching: true, error: false };

    case actions.PREDIO_CREATE_SUCCESS:
      return {
        ...state,
        // list: payload.data.data || [],
        list: [payload.data.data, ...state.list],
        isFetching: false,
        modalVisible: false,
        visible: false,
      };
    case actions.PREDIO_CREATE_ERROR:
      return { ...state, isFetching: false, error: true, notification: error.response.data.err };

    // UPDATE
    case actions.PREDIO_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PREDIO_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
        // list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
        currentItem: { socios: [] },
      };
    case actions.PREDIO_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    case actions.PREDIO_POBLACION_UPDATE_SUCCESS:
      return {
        ...state,
        currentItem: payload.data.currentItem,
        list: payload.data.data,
        isLoading: false,
      };
    case actions.PREDIO_POBLACION_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.PREDIO_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: action.payload.type || 'update',
      };
      
    // DELETE
    case actions.PREDIO_DELETE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PREDIO_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== payload.data.data.id),
        isFetching: false,
      };
    case actions.PREDIO_DELETE_ERROR:
      return { ...state, isFetching: false };

    // MODAL
    case actions.PREDIO_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.PREDIO_SHOW_MODAL_CLONE:
      return { ...state, visible: !state.visible, currentItem: action.payload, };

    case actions.PREDIO_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    case actions.PREDIO_ABRIR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: true };

    case actions.PREDIO_CERRAR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: false };

    default:
      return state;
  }
}
