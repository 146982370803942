import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  PREDIOS_FETCH_REQUEST: 'PREDIOS_FETCH_REQUEST',
  PREDIOS_FETCH_SUCCESS: 'PREDIOS_FETCH_SUCCESS',
  PREDIOS_FETCH_ERROR: 'PREDIOS_FETCH_ERROR',

  PREDIO_CREATE_REQUEST: 'PREDIO_CREATE_REQUEST',
  PREDIO_CREATE_SUCCESS: 'PREDIO_CREATE_SUCCESS',
  PREDIO_CREATE_ERROR: 'PREDIO_CREATE_ERROR',

  PREDIO_UPDATE_REQUEST: 'PREDIO_UPDATE_REQUEST',
  PREDIO_UPDATE_SUCCESS: 'PREDIO_UPDATE_SUCCESS',
  PREDIO_UPDATE_ERROR: 'PREDIO_UPDATE_ERROR',

  PREDIO_DELETE_REQUEST: 'PREDIO_DELETE_REQUEST',
  PREDIO_DELETE_SUCCESS: 'PREDIO_DELETE_SUCCESS',
  PREDIO_DELETE_ERROR: 'PREDIO_DELETE_ERROR',

  PREDIO_POBLACION_UPDATE_REQUEST: 'PREDIO_POBLACION_UPDATE_REQUEST',
  PREDIO_POBLACION_UPDATE_SUCCESS: 'PREDIO_POBLACION_UPDATE_SUCCESS',
  PREDIO_POBLACION_UPDATE_ERROR: 'PREDIO_POBLACION_UPDATE_ERROR',

  PREDIO_UPDATE_MODAL: 'PREDIO_UPDATE_MODAL',
  PREDIO_SHOW_MODAL: 'PREDIO_SHOW_MODAL',
  PREDIO_SHOW_MODAL_CLONE: 'PREDIO_SHOW_MODAL_CLONE',
  PREDIO_HIDE_MODAL: 'PREDIO_HIDE_MODAL',

  PREDIO_ABRIR_FILTROS_BUSQUEDA: 'PREDIO_ABRIR_FILTROS_BUSQUEDA',
  PREDIO_CERRAR_FILTROS_BUSQUEDA: 'PREDIO_CERRAR_FILTROS_BUSQUEDA',

  // Acciones
  searchPredios: (data) => ({
    types: [action.PREDIOS_FETCH_REQUEST, action.PREDIOS_FETCH_SUCCESS, action.PREDIOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/search_predios`,
        data,
      }
    }
  }),
  addPredio: data => ({
    types: [action.PREDIO_CREATE_REQUEST, action.PREDIO_CREATE_SUCCESS, action.PREDIO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/predios`,
        data,
      }
    }
  }),
  editPredio: data => ({
    types: [action.PREDIO_UPDATE_REQUEST, action.PREDIO_UPDATE_SUCCESS, action.PREDIO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/predios/${data.id}`,
        data,
      }
    }
  }),
  updatePredioPoblacion: data => ({
    types: [action.PREDIO_POBLACION_UPDATE_REQUEST, action.PREDIO_POBLACION_UPDATE_SUCCESS, action.PREDIO_POBLACION_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/corrida_poblacion/${data.id}`,
        data,
      }
    }
  }),
  deletePredio: data => ({
    types: [action.PREDIO_DELETE_REQUEST, action.PREDIO_DELETE_SUCCESS, action.PREDIO_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/predios/${data.id}`,
      }
    }
  }),
  getPredios: data => ({
    types: [action.PREDIOS_FETCH_REQUEST, action.PREDIOS_FETCH_SUCCESS, action.PREDIOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: '/predios',
      }
    }
  }),
  updateModal: (payload, tipo) => ({
    type: action.PREDIO_UPDATE_MODAL,
    payload,
    tipo,
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PREDIO_SHOW_MODAL });
    };
  },
  showModalClone: (payload) => {
    return (dispatch) => {
      dispatch({ type: action.PREDIO_SHOW_MODAL_CLONE, payload });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PREDIO_HIDE_MODAL });
    }
  },
  // Abrir filtros
  abrirFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.PREDIO_ABRIR_FILTROS_BUSQUEDA });
      dispatch({ type: CERRAR_SIDEBAR });
    }
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.PREDIO_CERRAR_FILTROS_BUSQUEDA });
      dispatch({ type: ABRIR_SIDEBAR });
    }
  },
};
export default action;
