import request from '@/lib/helpers/request';
import config from '@/config';

export async function getSeries(option) {
  return request(`${config.apiUrl}/series/activos`, {
    method: 'GET',
  }, option.token);
}

export async function getClientes(option) {
  return request(`${config.apiUrl}/clientes/activos`, {
    method: 'GET',
  }, option.token);
}

export async function getPeriodos(option) {
  return request(`${config.apiUrl}/periodos`, {
    method: 'GET',
  }, option.token);
}

export async function getPredios(option) {
  // predios?status=1
  return request(`${config.apiUrl}/predios`, {
    method: 'GET',
  }, option.token);
}

export async function getVendedores(option) {
  return request(`${config.apiUrl}/vendedores`, {
    method: 'GET',
  }, option.token);
}
